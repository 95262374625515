import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"users",
        "id":"user_id",
        "nameSingle":"user",
        "nameMultiple":"users",
        "options":['insert','delete','edit'],
        "filters":
        [
            {
                "key":"state",
                "name":"State",
                "options":[
                    {key:"0",value:"All"},
                    {key:"1",value:"Pending"},
                    {key:"2",value:"Active"}
                ]
            }
        ]
        ,
        "fields":{
            "field0":{
                "name":"Name",
                "field":"user_name",
                "type":"TextInput",
                "required":false,
                "list":true,
            },
            "field1":{
                "name":"E-mail",
                "field":"user_email",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Password",
                "field":"user_password",
                "type":"PasswordInput",
                
                "list":false
            },
            "field3":{
                "name":"Type",
                "field":"user_type",
                "type":"DropDown",
                "options": {
                    "kid":"Kid",
                    "adult":"Adult",
                    "kid_test":"Kid Test",
                    "adult_test":"Adult Test"
                },
                "list":true,
                "required":true,

            },
           
            "field4":{
                "name":"Registration",
                "field":"user_registration_date",
                "type":"DateInput",
                "required":false,
                "list":true,
            },
            "field5":{
                "name":"Credits",
                "field":"user_credits",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field6":{
                "name":"Type",
                "field":"user_pay_type",
                "type":"Display",
                "required":false,
                "list":true,
            },
            "field6":{
                "name":"State",
                "field":"user_state",
                "type":"Display",
                "required":false,
                "list":true,
            },
            "field7":{
                "name":"Campaigns",
                "field":"campaigns",
                "type":"Display",
                "required":false,
                "list":true,
            },
            
        }
    }
}